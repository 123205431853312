import {createContext, useEffect} from "react";


const AuthContext = createContext({
    isAuth: false,
    setisAuth: () => {}
})




export default AuthContext;