import '../../index.scss';
import './Home.scss';

export default function Home() {


    return (

        <section className="section-home">
            <h1>Tu as besoin d'une information rapidement ?</h1>
            <h1>Fais ta recherche sur notre outil 10 fois plus rapide qu'IW3.</h1>
        </section>

    )


}
