import {useContext, useRef} from "react";
import {signin} from "../../utils/global_data/api_service";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../utils/contexts/authContext";

export default function Login() {

    const mail = useRef(null)
    const pass = useRef(null)
    const navigate = useNavigate();
    const {isAuth, setisAuth} = useContext(AuthContext);

    const fetch_data = async (mail, password) => {

        return await signin(mail, password);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = await fetch_data(mail.current.value, pass.current.value)
        if(data) {
            setisAuth(true);
            navigate("/");
        } else {
            navigate("/login");
        }
    }


    return (
        <section className="section-signup">
            <div className="overlay-inscription"></div>
            <form onSubmit={handleSubmit} className="formulaire-signup">
                <div className="en-tete">Connexion</div>
                <div className="div-inputs">
                    {/*<label htmlFor="mail">E-mail: </label>*/}
                    <input ref={mail} placeholder="E-mail" type="email" id="mail"/>
                </div>

                <div className="div-inputs">
                    {/*<label htmlFor="pass">Mot de passe: </label>*/}
                    <input ref={pass} placeholder="Mot de passe" type="password" id="pass"/>
                </div>

                <button type="submit" onClick={handleSubmit} className="inscription-btn">Se connecter</button>

            </form>
        </section>
    )
}