import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Nro from "./Components/NroPage/Nro";
import Signup from "./Components/Signup/Signup";
import Login from "./Components/Login/Login";
import AuthContext from "./utils/contexts/authContext";

function App() {
    const [isAuth, setisAuth] = useState(false);


    return (
        <React.StrictMode>
            <AuthContext.Provider value={{ isAuth, setisAuth }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Navbar/>}>
                            <Route index element={<Home/>} />
                            <Route path="/nro/:name" element={<Nro/>}/>
                            <Route path="/signup" element={<Signup/>}/>
                            <Route path="/login" element={<Login/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthContext.Provider>
        </React.StrictMode>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
