import {api_headers, global_variables} from "./global_variables";

const API = global_variables.API_URL;


// LOGIN METHODS


export async function signup(nom, prenom, tel, mail, password) {
	const body_request = {
		"nom": nom,
		"prenom": prenom,
		"tel": tel,
		"mail": mail,
		"password": password
	};

	const options = {
		method: 'POST',
		headers: api_headers,
		body: JSON.stringify(body_request), // Assurez-vous de réassigner la version JSON.stringify
	};

	const response = await fetch(API + "inscription", options);
	return await response.json();
}

export async function signin(mail, password) {
	const body_request = {
		"mail": mail,
		"password": password
	};

	const options = {
		method: 'POST',
		headers: api_headers,
		body: JSON.stringify(body_request), // Assurez-vous de réassigner la version JSON.stringify
	};

	const response = await fetch(API + "login", options);
	return await response.json();
}


export async function auth_verif() {
	const api_headers_verif = new Headers({
		'Content-Type': 'application/json',
		'X-API-KEY': 'HZELHHAKJHGFDSQRTYUIOPM'
	})
	const body = {

	}

	const options = {
		method: 'POST',
		headers: api_headers_verif,
		body: JSON.stringify(body)
	}

	try {
		await fetch(API + "verif", options);


	}
	catch (error) {
		console.log("Voici une erreur: ", error);
	}
	// return await response.json();
}




export async function readListNro() {

	const body_request = {

	};

	const options = {
		method: 'POST',
		headers: api_headers,
		body: JSON.stringify(body_request), // Assurez-vous de réassigner la version JSON.stringify
	};

	const response = await fetch(API + 'readlistnro', options);
	return await response.json();
}



export async function readinfos(user_id, nro_name) {

	const body_request = {
		"user_id": user_id,
		"nro_name": nro_name
	};

	const options = {
		method: 'POST',
		headers: api_headers,
		body: JSON.stringify(body_request),
	};

	const response = await fetch(API + 'readinfos', options);
	const data = await response.json();

	switch (data.data.nro.typen) {
		case "MPO":
			data.data.cables.map((brk) => {
				if(brk.nom_complet !== null){
					return brk.nom_complet = brk.nom_complet.split(" ").join('');

				}
				else {
					return brk.nom_complet = "Not Provided";
				}
			})
			break;
		case "BRK":
			break;

		default:
			break;
	}
	return data;
}


export async function readcable(cable_id) {

	const body_request = {
		"cable_id": cable_id
	};

	const options = {
		method: 'POST',
		headers: api_headers,
		body: JSON.stringify(body_request),
	};

	const response = await fetch(API + 'readcable', options);
	return await response.json();
}