import './Modal.scss';

export default function Modal({nom_connecteur, closeModal, displaynames}) {




    return (
        <>
            <div onClick={closeModal} className="overlay-modal">
                <div className="content-modal" onClick={(e) => {e.stopPropagation()}}>


                    <span className="entete-modal">
                        <p>Nom connecteur: <strong>{nom_connecteur}</strong></p>
                    </span>


                    <div className="container-modal">

                        <ul>
                            {displaynames.display_name.map((value, index) => {
                                return <li key={index} className="display-names-modal">{value}</li>
                            })}
                        </ul>

                        <ul>
                            {displaynames.data_value.map((value, index) => {
                                return <li key={index} className="data-values-modal">{value}</li>
                            })}

                        </ul>

                    </div>

                    <input className="btn-close-modal" onClick={closeModal} type="button" value="Fermer" />
                </div>
            </div>
        </>
    )
}
