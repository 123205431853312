
import fleche from '../../assets/img/fleche-menu.png';
import './Card.scss';
export default function Card({name, onClickCard}) {




    return (
        <div className="container" onClick={onClickCard}>
            <div className="infos">
                <h2>{name}</h2>
            </div>

            <div className="arrow">
                <img src={fleche} alt="fleche carte brk"/>
            </div>
        </div>
    )

}