import React, {useContext, useEffect, useState} from "react";
import fleche_menu from '../../assets/img/fleche-menu.png';
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import './Navbar.scss';
import {auth_verif, readListNro} from '../../utils/global_data/api_service.jsx';
import AuthContext from "../../utils/contexts/authContext";
import Cookie from "js-cookie";

export default function Navbar() {
    const [listeNro, setListeNro] = useState([]);
    const {isAuth, setisAuth} = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const jeton = Cookie.get('as');
    console.log(jeton);
    const fetchdata = async () => {
        try {
            const data = await readListNro();
            console.log("Données reçues: ", data.data)
            setListeNro(data.data);
        } catch (error) {
            console.log("Erreur lors de la récupération de la liste NRO:", error);
        }
    };

    useEffect(() => {
        fetchdata();
    }, [isAuth])


    useEffect(() => {
        if(Cookie.get('as') === "true") {
            setisAuth(true);
        } else {
            navigate('/login');
        }
    }, [location.pathname])

    useEffect(() => {

        async function verifyAuth() {
            try {
                auth_verif();
                const as = Cookie.get('as');
                if(as === "true") {
                    navigate('/');
                } else {
                    navigate('/login');
                }

            }
            catch(error) {
                    console.error("Une erreur est survenue lors de l'authentification: ", error)
            }
        }


        verifyAuth();


    }, [location.pathname]);





    return (
        <>
            <header>
                <nav>
                    <Link className="main-title" to="/">Brinder</Link>
                    <ul className="container-menu">
                        {isAuth &&
                            <li className="liste-nro">
                                NRO
                                <img src={fleche_menu} alt="fleche menu"/>
                                <ul className="deroulant-menu">
                                    {
                                        listeNro.map((nro, index) => {
                                            return (
                                                <Link to={`/nro/${nro.nom}`} key={index}>{nro.nom}</Link>
                                            )
                                        })
                                    }


                                </ul>
                            </li>
                        }
                        <li className="liste-nro">
                            Compte
                            <img src={fleche_menu} alt="fleche_menu"/>
                            <ul className="deroulant-menu">
                                {!isAuth && <Link to="/login">Connexion</Link> }
                                {!isAuth && <Link to="/signup">Inscription</Link> }
                                {isAuth && <Link to="/mon-compte">Mon compte</Link> }
                                {isAuth && <Link to="/signout">Se déconnecter</Link> }

                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>
            <main>
                <Outlet/>
            </main>
        </>
    );
}
