

export const global_variables = {
	// "API_URL": "https://v2.brinder.fr/apiz.php?mode=",
	// "API_URL_DEVMODE": "/api/?mode=",
	"API_URL": "https://brinder.bambou.ninja/apiz.php?mode="
}

export const api_headers = new Headers({
	'Content-Type': 'application/json',
	'X-API-KEY': 'HZELHHAKJHGFDSQRTYUIOPM'
});


