import './Signup.scss';
import {useRef, useState} from "react";
import {
    validate_mdp,
    validate_mail,
    check_length_pass,
    set_border_validate,
    set_border_invalidate,
    validate_firstname,
    validate_lastname,
    validate_tel
} from "../../utils/methods/data_validation";

import {signup} from "../../utils/global_data/api_service";

export default function Signup() {

    const prenom = useRef(null);
    const nom = useRef(null);
    const telephone = useRef(null);
    const email = useRef(null);
    const mdp = useRef(null);
    const mdp_verif = useRef(null);


    const [mdpok, setMdpok] = useState(true);
    const [mdp_length, setMdp_length] = useState(true);
    const [mailok, setMailok] = useState(true);
    const [nameok, setNameOk] = useState(true);
    const [lastnameok, setLastnameOk] = useState(true);
    const [telok, setTelOk] = useState(true);


    const fetch_data = async (nom, prenom, tel, mail, password) => {


        const response = await signup(nom, prenom, tel, mail, password);

        console.log(response);


    }

    const handleSubmit = (e) => {
        e.preventDefault();



        if(validate_firstname(prenom.current.value)) {
            set_border_validate(prenom.current);
            setNameOk(true);
        } else {
            set_border_invalidate(prenom.current);
            setNameOk(false);
        }

        if(validate_lastname(nom.current.value)) {
            set_border_validate(nom.current);
            setLastnameOk(true);
        } else {
            set_border_invalidate(nom.current);
            setLastnameOk(false);
        }

        if(validate_tel(telephone.current.value)) {
            set_border_validate(telephone.current);
            setTelOk(true);
        } else {
            set_border_invalidate(telephone.current);
            setTelOk(false);
        }


        if(validate_mail(email.current.value)) {
            set_border_validate(email.current);
            setMailok(true);

        } else {
            set_border_invalidate(email.current);
            setMailok(false);
        }

        if(validate_mdp(mdp.current.value, mdp_verif.current.value)) {

            setMdpok(true);

            if (!check_length_pass(mdp.current.value)) {
                setMdp_length(true);
                set_border_validate(mdp.current);
                set_border_validate(mdp_verif.current);
            } else {
                setMdp_length(false);
                set_border_invalidate(mdp.current);
            }
        }
        else {
            setMdpok(false);
            set_border_invalidate(mdp.current);
            set_border_invalidate(mdp_verif.current);
        }

        fetch_data(nom.current.value, prenom.current.value, telephone.current.value, email.current.value, mdp.current.value);

    }


    return (
        <section className="section-signup">
            <div className="overlay-inscription"></div>
            <form className="formulaire-signup">
                <div className="en-tete">Inscription</div>
                <div className="div-inputs">
                    { !nameok && <span style={{color: "red", fontSize: ".8em"}}>Les caractères spéciaux ne sont pas autorisés</span>}
                    <input placeholder="Prénom" ref={prenom} type="text" id="prenom" required/>
                </div>

                <div className="div-inputs">
                    { !lastnameok && <span style={{color: "red", fontSize: ".8em"}}>Les caractères spéciaux ne sont pas autorisés</span>}
                    <input placeholder="Nom" ref={nom} type="text" id="nom" required/>
                </div>
                
                <div className="div-inputs">
                    { !telok && <span style={{color: "red", fontSize: ".8em"}}>Taille max 10 chiffres. Les lettres ne sont pas autorisées.</span>}
                    <input placeholder="Téléphone" maxLength="10" minLength="10" ref={telephone} type="tel" id="telephone" required/>
                </div>
                
                <div className="div-inputs">
                    { !mailok && <span style={{color: "red", fontSize: ".8em"}}>L'adresse e-mail n'est pas valide</span>}
                    <input placeholder="E-mail" ref={email} type="email" id="mail" required/>
                </div>
                
                <div className="div-inputs">
                    { !mdpok && <span style={{color: "red", fontSize: ".8em"}}>Les mots de passe ne sont pas identique.</span>}
                    { !mdp_length && <span style={{color: "red", fontSize: ".8em"}}>Doit contenir 8 caractères ou plus</span>}
                    <input placeholder="Mot de passe" ref={mdp} type="password" id="pass" required/>
                </div>

                <div className="div-inputs">
                    { !mdpok && <span style={{color: "red", fontSize: ".8em"}}>Les mots de passe ne sont pas identique.</span>}
                    <input placeholder="Confirmation" ref={mdp_verif} type="password" id="pass-confirm" required/>
                </div>

                <button type="submit" onClick={handleSubmit} className="inscription-btn">S'inscrire</button>
                
            </form>
        </section>
    )
}