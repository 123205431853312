import {useNavigate, useParams} from "react-router-dom";
import { readinfos, readcable } from "../../utils/global_data/api_service";
import {useContext, useEffect, useRef, useState} from "react";
import Card from "../Card/Card";

import './Nro.scss';
import Modal from "../Modal/Modal";
import AuthContext from "../../utils/contexts/authContext";


export default function Nro() {



    const { name } = useParams();
    const [list_brk, set_list_brk] = useState([]);
    const [copied_list_brk, set_copied_list_brk] = useState([]);
    const [valueBrk, setValueBrk] = useState("");
    const [loading, setLoading] = useState(true);
    const recherche_input = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInfos, setModalInfos] = useState({});
    const [infoCable, setInfoCable] = useState([]);


    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            const data = await readinfos(1, name);
            set_list_brk(data.data.cables);
            set_copied_list_brk(data.data.cables);
            setLoading(false);
        };

        setValueBrk("");


        fetchdata();

    }, [name]);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (recherche_input.current.value === "") {
            return 0;
        }
        else {
            let new_list = copied_list_brk.filter(brk => brk.nom_complet.toLowerCase().includes(valueBrk.toLowerCase()));
            set_list_brk(new_list);
        }
    }

    const handleChange = (event) => {
        setValueBrk(event.target.value)
        if(event.target.value === "") {
            set_list_brk(copied_list_brk);
        }
    }

    const handleDelete = () => {
        setValueBrk("");
        set_list_brk(copied_list_brk);
    }


    const onClickCard = async (index) => {

        const fetch_readcable = async (index) => {
            const data = await readcable(index);
            setModalInfos(data.data);
            setInfoCable(data.data.infocable);
            setModalOpen(true);
        }
        fetch_readcable(index);


    };

    const closeModal = () => {
        setModalOpen(false);
    }


    return (
        <>
            <section className="section-nro">
                {modalOpen && <Modal closeModal={closeModal} nom_connecteur={modalInfos.nom_complet} displaynames={infoCable} />}
                <h1>Nro : {name}</h1>

                <form onSubmit={handleSubmit} className="formulaire-recherche-brk">
                    <div className="champ-de-recherche">
                        <input ref={recherche_input} onChange={handleChange} value={valueBrk} placeholder="Connecteur" className="barre-recherche" type="text"/>
                        <input onClick={handleDelete}className="btn-erase-barre-recherche" type="button" value="x"/>
                    </div>

                </form>

                <div className="container-nro">
                    {loading ? (
                        <p>Chargement en cours</p>
                    ) : (
                        list_brk.map((brk, index) => {
                            return (
                                <Card key={index} name={brk.nom_complet} onClickCard={() => { onClickCard(brk.id) }} />
                            )
                        })
                    )}
                </div>
            </section>

        </>

    );
}
