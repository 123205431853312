
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/;
const nomRegex = /^[a-zA-ZàáâäãåæçèéêëìíîïðòóôöõøùúûüýÿÀÁÂÄÃÅÆÇÈÉÊËÌÍÎÏÐÒÓÔÖÕØÙÚÛÜÝ\-]+$/;
const telRegex = /^[0-9]+$/;



export function set_border_invalidate(ref) {
    ref.style.border = "1px solid red";
}

export function set_border_validate(ref) {
    ref.style.border = "1px solid green";
}





export function validate_mdp(mdp, mdp_verif) {
    return mdp === mdp_verif && mdp !== "" && mdp_verif !== "";
}
export function check_length_pass(mdp) {
    return mdp > 8;
}


export function validate_mail(email) {
    return emailRegex.test(email);
}


export function validate_firstname(firstname_to_check) {
    return nomRegex.test(firstname_to_check);
}

export function validate_lastname(lastname_to_check) {
    return nomRegex.test(lastname_to_check);
}

export function validate_tel(telephone) {
    return telephone.length === 10 && telRegex.test(telephone);
}